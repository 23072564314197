body {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400;
  color: #414141 !important;
  background-color: #eee !important;
}

h1,
.h1 {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 1.375rem !important;
  font-weight: 700 !important;
}

h1 a,
h1 a:focus,
h1 a:visited,
.h1 a,
.h1 a:focus,
.h1 a:visited {
  color: #414141 !important;
}

h2 {
  font-size: 1.375rem !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}

h3 {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 1.25rem !important;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 0.875rem !important;
}

button {
  display: inline-block;
  vertical-align: middle;
}

button:focus {
  outline: none !important;
}

img,
svg {
  display: inline-block;
}

a,
a:focus,
a:visited {
  color: #1c5687 !important;
  text-decoration: none !important;
  background: transparent !important;
}

a:hover {
  color: #1c5687 !important;
  text-decoration: underline !important;
}

.font-14 {
  font-size: 0.875rem;
}

.font-16 {
  font-size: 1rem;
}

.font-22 {
  font-size: 1.375rem;
}

.font-40 {
  font-size: 2.5rem;
}

.text-bold {
  font-weight: 700;
}

.text-talic {
  font-style: italic;
}

.color-yellow {
  color: #ffd200;
}

.color-red {
  color: #eb0000;
}

.lh-1 {
  line-height: 1;
}

.ico-18 {
  width: 18px !important;
  height: auto !important;
}

.ico-36 {
  width: 36px !important;
  height: auto !important;
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  background-color: #f3a525;
  z-index: 1;
}

aside {
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  width: 250px;
  background-color: #707070;
  overflow: auto;
  z-index: 1;
}

main {
  position: relative;
  padding-top: 50px;
  padding-right: 0;
  padding-left: 250px;
  transition: padding-left 0.2s;
}

main.expd {
  padding-left: 40px;
}

main.reset {
  padding-left: 0 !important;
}

.content-box {
  position: relative;
  min-height: calc(100vh - 90px);
  padding: 20px;
}

footer {
  height: 40px;
  background-color: #dfdfdf;
  font-size: 0.75rem;
  padding: 0 20px;
}

.collapse-pan {
  height: 40px;
  padding: 8px;
  text-align: right;
}

.main-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.main-nav a {
  min-height: 40px;
  display: flex;
  align-items: center;
  color: #dfdfdf !important;
  text-decoration: none;
}
.main-nav a span {
  width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.main-nav a:hover {
  background-color: #414141;
  color: #fff !important;
}
.main-nav a.active,
.main-nav a.active:hover {
  background-color: #414141 !important;
  color: #fff !important;
}

.btn-filled {
  min-width: 126px;
  height: 45px;
  padding-right: 12px;
  padding-left: 12px;
  background-color: #414141;
  color: #fff;
  font-weight: 700;
  line-height: normal;
  border: 0;
  border-radius: 5px;
  outline: none;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.btn-filled:visited {
  background-color: #414141;
  border: 0;
  outline: none;
}
.btn-filled:hover {
  background-color: #414141;
  border: 0;
  outline: none;
}
.btn-filled:active {
  background-color: #282828;
  border: 0;
  outline: none;
}

.btn-lined {
  min-width: 126px;
  height: 45px;
  padding-right: 12px;
  padding-left: 12px;
  background-color: #fff;
  color: #414141;
  font-weight: 700;
  line-height: normal;
  border: 1px solid #414141 !important;
  border-radius: 5px;
  outline: none;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.btn-lined:visited {
  background-color: #fff;
  border: 1px solid #414141 !important;
  outline: none;
}
.btn-lined:hover {
  background-color: #fff;
  border: 1px solid #414141 !important;
  outline: none;
}
.btn-lined:active {
  background-color: #e0e0e0;
  border: 1px solid #414141 !important;
  outline: none;
}

.btn-24x24 {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: 0 !important;
  outline: none;
  line-height: 1 !important;
}
.btn-24x24:visited, .btn-24x24:hover, .btn-24x24:active {
  outline: none;
}

.btn-flat {
  padding: 0 !important;
  background-color: transparent !important;
  border: 0 !important;
  outline: none;
  font-size: 0.875rem !important;
  color: #414141 !important;
  line-height: 24px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.btn-flat:visited, .btn-flat:hover, .btn-flat:active {
  outline: none;
}
.btn-flat img {
  margin-left: 6px;
}

.btn-flat.txtbtn {
  font-size: 0.8125rem !important;
}

.form-label {
  margin-bottom: 6px !important;
  font-size: 0.875rem !important;
  font-weight: 700;
  padding-left: 12px;
}

.form-control {
  width: 280px !important;
  height: 40px !important;
  padding: 10px 12px !important;
  border: 1px solid #8c8c8c !important;
  border-radius: 5px !important;
  font-family: "Noto Sans", sans-serif !important;
  font-size: 0.875rem !important;
  color: #333 !important;
}
.form-control ::-moz-placeholder {
  color: rgba(51, 51, 51, 0.5);
}
.form-control ::placeholder {
  color: rgba(51, 51, 51, 0.5);
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #2b72cc !important;
}

.form-select {
  font-size: 0.875rem !important;
}

.form-textarea {
  min-height: 100px !important;
}

.form-control::-webkit-file-upload-button {
  padding: 10px 12px 7px !important;
  margin: -10px -12px 0 !important;
  margin-inline-end: 12px !important;
}

.input-group {
  flex-wrap: nowrap !important;
}
.input-group .input-group-text {
  padding: 0 12px !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: normal !important;
  color: #333 !important;
  background-color: #fff !important;
  border: 1px solid #8c8c8c !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.input-group .input-group-text.rt {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: auto !important;
}
.input-group .form-control.rt {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group .form-control[type=file] {
  border-radius: 5px !important;
}

.disabled .form-control,
.disabled .input-group-text,
.disabled .dpdown {
  pointer-events: none;
  cursor: default;
  opacity: 0.5 !important; /*border-color: $gray-3 !important; background-color: $gray-3 !important;*/
}

.btn-disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.required-field:after {
  content: " *";
  color: #eb0000 !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mx-8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.px-12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.w-90 {
  width: 90px;
}

.w-150 {
  width: 150px;
}

.w-160 {
  width: 160px;
}

.w-180 {
  width: 180px;
}

.w-185 {
  width: 185px;
}

.w-200 {
  width: 200px;
}

.w-220 {
  width: 220px;
}

.w-240 {
  width: 240px;
}

.w-260 {
  width: 260px;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-430 {
  width: 430px !important;
}

.m-w-880 {
  min-width: 880px;
}

.input-search {
  background-image: url(../Assets/icon-search.svg);
  background-position: left 12px center;
  background-repeat: no-repeat;
  padding-right: 24px !important;
  padding-left: 48px !important;
}

.clearable {
  border: 0 !important;
  background: url(../Assets/icon-close.svg) center center no-repeat;
  background-size: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 8px;
}

.input-search:not(:valid) ~ .clearable {
  display: none;
}

.data-table {
  width: 100%;
  border-collapse: collapse !important;
  font-size: 0.875rem;
  color: #414141;
  table-layout: inherit;
}
.data-table th {
  font-size: 1rem !important;
  font-weight: 700 !important;
  background-color: #eee !important;
}
.data-table thead.popup th {
  font-size: 0.875rem !important;
  background-color: #fff !important;
  height: auto !important;
  padding: 3px 12px;
}
.data-table th,
.data-table td {
  padding: 5px 12px !important;
  height: 40px;
}
.data-table tbody tr:nth-of-type(odd) {
  background-color: #dfdfdf !important;
}
.data-table tbody tr:nth-of-type(even) {
  background-color: #fff !important;
}

.MuiTableCell-root {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 0.875rem !important;
  color: #414141 !important;
  border-bottom: 0 !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiPaper-root {
  color: #414141 !important;
}

.fixed-table {
  overflow-y: auto;
  max-height: 200px;
}
.fixed-table th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.line-table {
  width: 100%;
  border-collapse: collapse !important;
  font-size: 0.875rem;
  color: #414141;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.line-table th {
  font-weight: 700 !important;
  background-color: #dfdfdf !important;
}
.line-table th,
.line-table td {
  padding: 5px 12px !important;
  height: 40px;
}
.line-table td {
  border-top: 1px solid #eee;
}

#popup600 .MuiDialog-paperWidthSm {
  width: 100%;
  max-width: 600px !important;
}
#popup600 .MuiTypography-h6 {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 1rem !important;
  color: #414141 !important;
  font-weight: 700 !important;
  text-align: center;
}
#popup600 .MuiIconButton-root {
  padding: 0 !important;
  color: #414141 !important;
}
#popup600 .MuiIconButton-root:hover {
  background-color: transparent !important;
}
#popup600 .MuiDialogContent-dividers {
  padding: 24px 12px !important;
  border-top: 1px solid #dfdfdf !important;
  border-bottom: 0 !important;
}

#popup800 .MuiDialog-paperWidthSm {
  width: 100%;
  max-width: 800px !important;
}
#popup800 .MuiTypography-h6 {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 1rem !important;
  color: #414141 !important;
  font-weight: 700 !important;
  text-align: center;
}
#popup800 .MuiIconButton-root {
  padding: 0 !important;
  color: #414141 !important;
}
#popup800 .MuiIconButton-root:hover {
  background-color: transparent !important;
}
#popup800 .MuiDialogContent-dividers {
  padding: 24px 12px !important;
  border-top: 1px solid #dfdfdf !important;
  border-bottom: 0 !important;
}

.MuiTypography-h6 {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 1rem !important;
  color: #414141 !important;
  font-weight: 700 !important;
  text-align: center;
}

/* Custom Scrollbar */
.ScrollbarsCustom-TrackY {
  border-radius: 0 !important;
  background-color: #e1e1e1 !important;
  height: 100% !important;
  top: 0 !important;
}

.ScrollbarsCustom-ThumbY,
.ScrollbarsCustom-ThumbX {
  border-radius: 5px !important;
  background-color: #707070 !important;
}

.ScrollbarsCustom-TrackX {
  border-radius: 0 !important;
  background-color: #e1e1e1 !important;
  width: calc(100% - 12px) !important;
  left: 0 !important;
}

.ScrollbarsCustom-Content {
  padding: 0 !important;
}

/* Left Navigation */
.MuiList-padding.sidebar-nav {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.sidebar-nav .MuiListItem-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sidebar-nav .MuiListItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sidebar-nav .MuiListItemText-root {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.sidebar-nav .MuiTypography-body1 {
  font-family: "Noto Sans", sans-serif !important;
  color: #dfdfdf !important;
  letter-spacing: normal !important;
}
.sidebar-nav .MuiListItem-button:hover {
  background-color: #414141 !important;
}
.sidebar-nav .MuiListItem-root.Mui-selected,
.sidebar-nav .MuiListItem-root.Mui-selected:hover {
  background-color: #414141 !important;
}
.sidebar-nav .MuiListItem-root.Mui-selected .MuiTypography-body1,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .MuiTypography-body1 {
  color: #fff !important;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-dashboard,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-dashboard {
  background-position: 0 -40px;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-hospitals,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-hospitals {
  background-position: -40px -40px;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-tablet,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-tablet {
  background-position: -80px -40px;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-devices,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-devices {
  background-position: -120px -40px;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-softupgrade,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-softupgrade {
  background-position: -160px -40px;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-calibration,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-calibration {
  background-position: -200px -40px;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-usermanagement,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-usermanagement {
  background-position: -240px -40px;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-reports,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-reports {
  background-position: -280px -40px;
}
.sidebar-nav .MuiListItem-root.Mui-selected .icon-eifu,
.sidebar-nav .MuiListItem-root.Mui-selected:hover .icon-eifu {
  background-position: -320px -40px;
}
.sidebar-nav .icon-nav {
  width: 40px;
  height: 40px;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-right: 12px;
}
.sidebar-nav .icon-dashboard {
  background-position: 0 0;
}
.sidebar-nav .icon-hospitals {
  background-position: -40px 0;
}
.sidebar-nav .icon-tablet {
  background-position: -80px 0;
}
.sidebar-nav .icon-devices {
  background-position: -120px 0;
}
.sidebar-nav .icon-softupgrade {
  background-position: -160px 0;
}
.sidebar-nav .icon-calibration {
  background-position: -200px 0;
}
.sidebar-nav .icon-usermanagement {
  background-position: -240px 0;
}
.sidebar-nav .icon-reports {
  background-position: -280px 0;
}
.sidebar-nav .icon-eifu {
  background-position: -320px 0;
}

/* Loader css */
.newloader-style {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}
.newloader-style:after {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  content: "";
  background-color: rgba(65, 65, 65, 0.5);
  pointer-events: none;
}
.newloader-style .newloader-style img {
  z-index: 2;
  position: relative;
}

.rowperpagedropdown {
  width: 80px;
  padding-top: 10px;
}

/* Login */
.login-l-aside {
  background: #f3a525 url(../Assets/login-bg.png) bottom center no-repeat;
  background-size: 100%;
  background-blend-mode: multiply;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-l-aside .login-logo {
  height: 80px;
}
@media (max-width: 1199px) {
  .login-l-aside .login-logo {
    height: 60px;
  }
}

.login-r-aside {
  display: flex;
  flex-direction: column;
}
.login-r-aside .login-outer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.login-r-aside .login-outer .login-box {
  min-width: 340px;
}
@media (max-width: 1199px) {
  .login-r-aside .login-outer .login-box {
    min-width: 280px;
  }
}
.login-r-aside .login-outer .invalid-feedback {
  display: block;
  position: absolute;
  bottom: -20px;
}
@media (max-width: 1199px) {
  .login-r-aside .login-outer .invalid-feedback {
    bottom: -18px;
  }
}
.login-r-aside .login-footer {
  height: 40px;
  background-color: #dfdfdf;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.invalid-feedback {
  display: block !important;
  font-size: 0.875rem !important;
  position: absolute;
  bottom: -20px;
}
@media (max-width: 1199px) {
  .invalid-feedback {
    bottom: -18px;
  }
}

.captcha-container {
  position: absolute;
  bottom: 52px;
  right: 0;
}
.captcha-container .captcha-error-message {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  min-height: 60px;
  width: 256px;
  font-size: 0.875rem;
  background-color: #fff;
  color: #eb0000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Custom Checkbox */
.custom-chkbx {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  min-height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 0.875rem;
  vertical-align: middle;
}

/* Hide the browser's default checkbox */
.custom-chkbx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-chkbx .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 1px solid #8c8c8c;
  border-radius: 2px;
}

/* On mouse-over, add a background color */
.custom-chkbx:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a background color */
.custom-chkbx input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-chkbx .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-chkbx input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-chkbx .checkmark:after {
  left: 8px;
  top: 3px;
  width: 8px;
  height: 14px;
  border: solid #414141;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-chkbx .checkmark.initial:after {
  border: solid rgba(65, 65, 65, 0.5);
  border-width: 0 2px 2px 0;
}

/* Custom Raido */
.custom-radio {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 36px;
  min-height: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 0.875rem;
  vertical-align: middle;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #8c8c8c;
  border-radius: 50%;
}

/* On mouse-over, add a background color */
.custom-radio:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add background color */
.custom-radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #414141;
}

.icon-eye {
  position: absolute;
  right: 12px;
  bottom: 8px;
}
.icon-eye svg {
  fill: rgba(65, 65, 65, 0.5);
}

.Toastify__toast-container {
  width: 100%;
  padding: 0;
  z-index: 999;
}

.Toastify__toast-container--top-left {
  top: 50px;
  left: 0;
  margin: 0;
}

.Toastify__toast-container--beforeUserLoggedInMessage {
  top: -16px !important;
  left: 16px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.Toastify__toast {
  min-height: 40px !important;
  padding: 0 12px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  font-family: "Noto Sans", sans-serif !important;
  font-size: 0.875rem;
}

.Toastify__toast--info {
  background: #2b72cc;
}

.Toastify__toast--success {
  background: #3a913f;
}

.Toastify__toast--warning {
  background: #ffd200;
  color: #414141 !important;
}

.Toastify__toast--error {
  background: #eb0000;
}

.sts-box {
  width: 18px;
  height: 18px;
  line-height: 18px !important;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
}

.sts-grn {
  background-color: #3a913f;
}

.sts-red {
  background-color: #eb0000;
}

.sts-yellow {
  background-color: #ffd200;
}

.sts-txt-pending {
  color: #f3a525;
}

.sts-txt-failed {
  color: #eb0000;
}

/* Accordion */
.str-acrd .MuiPaper-root {
  background-color: transparent !important;
}
.str-acrd .MuiAccordion-rounded:first-child {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.str-acrd .MuiAccordion-rounded:last-child {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.str-acrd .MuiAccordionSummary-root {
  align-items: center !important;
  flex-direction: row-reverse !important;
  padding: 0 !important;
  min-height: auto !important;
  transition: none !important;
}
.str-acrd .MuiAccordionSummary-content {
  margin: 0 !important;
  justify-content: space-between !important;
}
.str-acrd h2 {
  font-size: 1.375rem !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}
.str-acrd .MuiIconButton-edgeEnd {
  margin-right: 0 !important;
}
.str-acrd .MuiIconButton-root {
  padding: 0 !important;
  margin-top: 3px !important;
  margin-right: 12px !important;
  color: #414141 !important;
}
.str-acrd .MuiAccordionDetails-root {
  padding: 12px 0 0 0 !important;
  display: block !important;
}
.str-acrd .Mui-focused {
  background: transparent !important;
}

.shadow-box {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  margin: 12px 0;
}
.shadow-box .shadow-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 12px;
  border-bottom: 1px solid #dfdfdf;
}
.shadow-box .shadow-body {
  overflow-x: auto;
  padding: 5px 0;
}

/*! react-sidenav v0.5.0 | (c) 2020 Trend Micro Inc. | MIT | https://github.com/trendmicro-frontend/react-sidenav */
.sidenav---sidenav---_2tBP {
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  z-index: 3;
  min-width: 40px;
  background: #707070;
  transition: min-width 0.2s;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 {
  display: none;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---sidenav-subnav---1EN61 {
  display: block;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 250px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  visibility: visible;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  transition: opacity 0.2s linear;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f span {
  padding-left: 12px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 > .sidenav---navitem---9uL5T {
  cursor: pointer;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  position: static;
  border: none;
  float: left;
  clear: both;
  width: 100%;
  background: #707070;
  overflow: hidden;
  box-shadow: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  display: block;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  display: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 {
  position: relative;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  content: " ";
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  z-index: -1;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
  display: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover::before {
  background: #fff;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  content: " ";
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  z-index: -1;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
  color: #fff;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover > .sidenav---navitem---9uL5T {
  background: #414141;
  color: #dfdfdf;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  color: #dfdfdf;
  padding: 0 12px 0 52px;
}

.sidenav---sidenav-toggle---1KRjR {
  position: relative;
  float: right;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.sidenav---sidenav-toggle---1KRjR:focus {
  outline: none;
}

.sidenav---sidenav-nav---3tvij {
  float: left;
  padding: 0;
  margin: 0;
  clear: both;
  list-style: none;
  width: 100%;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
  clear: both;
  position: relative;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T::after {
  background: #414141;
  z-index: -1;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after {
  background: #414141;
  z-index: -1;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
  background: none;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
  background: none;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span {
  color: #fff;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-dashboard,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-dashboard,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-dashboard,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-dashboard {
  background-position: 0 -40px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-hospitals,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-hospitals,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-hospitals,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-hospitals {
  background-position: -40px -40px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-tablet,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-tablet,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-tablet,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-tablet {
  background-position: -80px -40px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-devices,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-devices,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-devices,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-devices {
  background-position: -120px -40px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-softupgrade,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-softupgrade,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-softupgrade,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-softupgrade {
  background-position: -160px -40px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-calibration,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-calibration,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-calibration,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-calibration {
  background-position: -200px -40px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-usermanagement,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-usermanagement,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-usermanagement,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-usermanagement {
  background-position: -240px -40px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-reports,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-reports,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-reports,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-reports {
  background-position: -280px -40px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-eifu,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .icon-eifu,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .icon-eifu,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f span .icon-eifu {
  background-position: -320px -40px;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
  color: #dfdfdf;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
  position: relative;
  display: block;
  line-height: 40px;
  height: 40px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:focus {
  outline: 0;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T::after {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #dfdfdf;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
  color: #dfdfdf;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  display: block;
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 0;
  line-height: 40px;
  text-align: center;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  width: 0;
  visibility: hidden;
  opacity: 0;
}

.sidenav---sidenav-subnav---1EN61 {
  min-width: 210px;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 0;
  left: 40px;
  background: #fff;
  border: 1px #8c8c8c solid;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
  margin: 0 12px;
  line-height: inherit;
  border-bottom: 1px #dfdfdf solid;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:nth-child(2) {
  margin-top: 0;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:last-child {
  margin-bottom: 0;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
  color: #fff;
  background: #414141;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T:hover {
  background: #414141;
  color: #fff;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  display: block;
  text-decoration: none;
  color: #414141;
  line-height: inherit;
  padding: 0 12px;
  cursor: pointer;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:hover {
  background: #414141;
  color: #fff;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:focus {
  outline: 0;
}

.sidenav---sidenav---_2tBP {
  box-sizing: border-box;
  line-height: 40px;
}

.sidenav---sidenav---_2tBP *,
.sidenav---sidenav---_2tBP *:before,
.sidenav---sidenav---_2tBP *:after {
  box-sizing: inherit;
}

.icon-nav {
  display: block;
  width: 40px;
  height: 40px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.icon-dashboard {
  background-position: 0 0;
}

.icon-hospitals {
  background-position: -40px 0;
}

.icon-tablet {
  background-position: -80px 0;
}

.icon-devices {
  background-position: -120px 0;
}

.icon-softupgrade {
  background-position: -160px 0;
}

.icon-calibration {
  background-position: -200px 0;
}

.icon-usermanagement {
  background-position: -240px 0;
}

.icon-reports {
  background-position: -280px 0;
}

.icon-eifu {
  background-position: -320px 0;
}

.icon-nav[title]:hover:after {
  content: attr(title);
  position: absolute;
  left: 40px;
  background: #414141;
  width: 210px;
  height: 40px;
  font-size: 1rem;
  color: #dfdfdf;
  text-align: left;
  padding-left: 12px;
}

.icon-exp {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background: url(../Assets/icon-down-white.svg) center center no-repeat;
}

.icon-col {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background: url(../Assets/icon-up-white.svg) center center no-repeat;
}

.drop-down1 {
  position: absolute;
  top: 46px;
  right: 0;
  font-size: 1rem;
  min-width: 140px;
  padding: 8px 0;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
  z-index: 4;
}
.drop-down1 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.drop-down1 ul li a {
  min-height: 40px;
  color: #333 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}
.drop-down1 ul li a:hover {
  color: #333 !important;
  background-color: rgba(65, 65, 65, 0.09) !important;
  text-decoration: none !important;
}

.cardbox {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background: #fff;
  border-radius: 5px;
}
.cardbox .card-head {
  min-height: 40px;
  border-bottom: 1px solid #dfdfdf;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.cardbox .card-body {
  min-height: 100px;
  padding: 12px;
  display: flex;
  align-items: center;
}
.cardbox .card-body .card-icon {
  width: 60px;
  height: 60px;
  border: 1px solid #8c8c8c;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.cardbox .card-body p {
  margin-bottom: 0.5rem;
}
.cardbox .card-foot {
  min-height: 30px;
  border-top: 1px solid #dfdfdf;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cur-pointer {
  cursor: pointer !important;
}

/* Create / Reset Password */
.create-pass {
  display: flex;
  justify-content: center;
}
.create-pass .pass-outer {
  display: flex;
}
.create-pass .pass-outer .pass-box {
  min-width: 340px;
}

/* Material UI Tree */
.MuiFormControlLabel-root {
  margin-left: 0 !important;
}

.MuiTreeItem-root {
  background-image: url(../Assets/icon-tree-dotline.png);
  background-position: 11px top;
  background-repeat: repeat-y;
  padding-bottom: 20px !important;
}

.MuiTreeItem-iconContainer {
  width: 24px !important;
  height: 24px;
  background-color: #eee;
}

.MuiTypography-body1 {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 1rem !important;
  color: #414141 !important;
  line-height: 1.2 !important;
}

.MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

.MuiTreeItem-group {
  margin-left: 27px !important;
}

.MuiTreeItem-group .MuiTreeItem-root {
  background-image: url(../Assets/icon-tree-divider1.png) !important;
  background-position: 11px top;
  background-repeat: no-repeat !important;
  padding-top: 13px !important;
  padding-bottom: 10px !important;
}

.Mui-expanded > .MuiTreeItem-content > .MuiTreeItem-label {
  font-weight: 700 !important;
}

.MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

.inner-cnt {
  padding: 5px 0 10px 17px;
  background: url(../Assets/icon-tree-divider1.png) 15px top repeat-y;
}

/* IOS style switch */
.MuiFormControlLabel-label {
  font-size: 1.375rem !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.ddown-hrs,
.ddown-min,
.ddown-apm {
  width: 80px !important;
}

.col-action {
  min-width: 130px !important;
  white-space: nowrap !important;
}

@media (max-width: 1199px) {
  /*$font-14: 0.875rem;
  $font-16: 0.9375rem;*/
  body {
    font-size: 0.875rem !important;
  }
  h1,
  .h1 {
    font-size: 1.25rem !important;
  }
  h2 {
    font-size: 1.25rem !important;
  }
  h3 {
    font-size: 1.125rem !important;
  }
  /*.font-14 { font-size: $font-14; }*/
  .font-16 {
    font-size: 0.875rem;
  }
  .font-22 {
    font-size: 1.25rem;
  }
  .font-40 {
    font-size: 2.125rem;
  }
  .data-table th {
    font-size: 0.875rem !important;
  }
  #popup600 .MuiTypography-h6 {
    font-size: 0.875rem !important;
  }
  #popup800 .MuiTypography-h6 {
    font-size: 0.875rem !important;
  }
  .MuiTypography-h6 {
    font-size: 0.875rem !important;
  }
  .invalid-feedback {
    font-size: 0.8125rem !important;
  }
  .str-acrd h2 {
    font-size: 1.25rem !important;
  }
  .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
    font-size: 0.875rem;
  }
  .icon-nav[title]:hover:after {
    font-size: 0.875rem;
  }
  .drop-down1 {
    font-size: 0.875rem;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem !important;
  }
  .MuiFormControlLabel-label {
    font-size: 1.25rem !important;
  }
  .fby {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}