/* https://github.com/YouCanBookMe/react-datetime */
/* @font-face { font-family: 'noto_sansregular'; src: url('./fonts/notosans-regular-webfont.woff2') format('woff2'), url('./fonts/notosans-regular-webfont.woff') format('woff'); font-weight: normal; font-style: normal; } */

.cal-container { position: relative; /*display: inline-flex;*/ }
.rdt { position: relative; font-size: 14px; }
.rdt .form-control { cursor: pointer; width: 100% !important; height: 40px; box-sizing: border-box; margin-bottom: 0px; padding: 10px 12px; border: 1px solid #8c8c8c; border-radius: 5px; outline: none; font-family: "Noto Sans", sans-serif; font-size: 14px; color: #333333; background-image: url(../Assets/icon-calendar.svg); background-position: center right 15px; background-repeat: no-repeat; }
.rdt .form-control[readonly] { background-color: #fff; }
::-webkit-input-placeholder { /* Edge */ color: rgba(51,51,51,.5); }
:-ms-input-placeholder { /* Internet Explorer 10-11 */ color: rgba(51,51,51,.5); }
::placeholder { color: rgba(51,51,51,.5); }
:focus { outline: none; box-shadow: none; border-color: #2b72cc; }
.disable-pointer { pointer-events: none; cursor: pointer; }
.pop-fixed .rdtPicker { position: fixed; }
.rdtPicker { display: none; position: /*fixed*/ absolute; width: 314px; height: auto/*306px*/; padding: 0; margin-top: 6px; z-index: 99999 !important; background: #fff; /*box-shadow: 0 1px 3px rgba(0,0,0,0.1);*/ border: 1px solid #8C8C8C; border-radius: 5px; }
.rdtOpen .rdtPicker { display: block; }
/*.rdtOpen .rdtTime { display: block; width: 314px; height: 150px; }
.rdtOpen .rdtDays { display: block; width: 314px; height: 300px; }*/
.rdtStatic .rdtPicker { box-shadow: none; position: static; }
.rdtPicker .rdtTimeToggle { text-align: center; }
.rdtPicker table { width: /*280px*/ 100%; margin: 10px auto 0 auto; border-collapse: collapse; border-spacing: 0; }
.rdtPicker td, .rdtPicker th { text-align: center; /*height: 28px;*/ }
.rdtPicker td { cursor: pointer; width: 40px; height: 40px; min-width: 40px; border-radius: 5px; }
.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover { background: rgba(41,41,41,0.09); cursor: pointer; }
.rdtPicker tfoot .rdtTimeToggle:hover { background: none; }
/*.rdtPicker tfoot tr:hover { background: rgba(41,41,41,0.09); cursor: pointer; }*/
.rdtPicker td.rdtOld, .rdtPicker td.rdtNew { color: rgba(41,41,41,0.3); }
.rdtPicker td.rdtToday { position: relative; }
.rdtPicker td.rdtToday:before { display: none; /* content: ""; display: inline-block; border-left: 7px solid transparent; border-bottom: 7px solid #428bca; border-top-color: rgba(0,0,0,0.2); position: absolute; bottom: 4px; right: 4px; */ }
.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover { background-color: #414141; color: #fff; /*text-shadow: 0 -1px 0 rgba(0,0,0,0.25);*/ }
.rdtPicker td.rdtActive.rdtToday:before { border-color: none; /* border-bottom-color: #fff; */ }
.rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover { background: none; color: #999999; cursor: not-allowed; }
.rdtPicker td span.rdtOld { color: #999999; }
.rdtPicker td span.rdtDisabled, .rdtPicker td span.rdtDisabled:hover { background: none; color: #999999; cursor: not-allowed; }
.rdtPicker th { border-bottom: 1px solid #f9f9f9; }
.rdtPicker .dow { width: 14.2857%; border-bottom: none; cursor: default; }
.rdtPicker th.rdtSwitch { width: 100px; }
.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev { font-size: 21px; vertical-align: top; }
.rdtPrev span, .rdtNext span { display: block; -webkit-touch-callout: none; /* iOS Safari */ -webkit-user-select: none; /* Chrome/Safari/Opera */ -khtml-user-select: none; /* Konqueror */ -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer/Edge */ user-select: none; }
.rdtPicker th.rdtDisabled, .rdtPicker th.rdtDisabled:hover { background: none; color: #999999; cursor: not-allowed; }
.rdtPicker thead tr:first-child th { cursor: pointer; text-transform: uppercase; width: 70px; }
.rdtPicker thead tr:first-child th:hover { background: none; /* background: rgba(41,41,41,0.09); */ }
.rdtPicker tfoot { border-top: 1px solid #8C8C8C; }
.rdtPicker button { border: none; background: none; cursor: pointer; }
.rdtPicker button:hover { background-color: #eee; }
.rdtPicker thead button { width: 100%; height: 100%; }
td.rdtMonth, td.rdtYear { height: /*70px*/ 50px; width: 50px; cursor: pointer; }
td.rdtMonth:hover, td.rdtYear:hover { background: #eee; }
.rdtCounters { display: inline-block; /*margin-top: 10px;*/ }
.rdtCounters > div { float: left; }
.rdtCounter { height: 100px; /*background-color: #f0f0f0; border-radius: 5px; margin: 0px 5px;*/ }
.rdtCounter { width: 40px; }
.rdtCounterSeparator { line-height: 100px; }
.rdtCounter .rdtBtn { /*height: 40%;*/ line-height: 40px; cursor: pointer; display: block; border-radius: 5px; -webkit-touch-callout: none; /* iOS Safari */ -webkit-user-select: none; /* Chrome/Safari/Opera */ -khtml-user-select: none; /* Konqueror */ -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer/Edge */ user-select: none; }
.rdtCounter .rdtBtn:hover { background: #eee; }
.rdtCounter .rdtCount { /*height: 20%;*/ font-size: 14px /*1.2em*/; }
.rdtMilli { vertical-align: middle; padding-left: 8px; width: 48px; }
.rdtMilli input { width: 100%; font-size: 1.2em; margin-top: 37px; }
.rdtTime td { cursor: default; }

/*.rdtSwitch, .rdtDays thead tr { background-color: #f0f0f0;}*/
.rdtDays thead th { height: 30px; }
.rdtDays tbody tr:last-child { display: none; }

.rdtDays thead:after { content: ""; display: block; height:1px; width: 100%; background: #8C8C8C; position: absolute; }
.rdtDays tbody:before, .rdtDays tbody:after { content: ""; display: block; height:16px; width: 100%; }
.rdtDays tfoot tr:before, .rdtDays thead tr:before, .rdtDays tbody tr:before, .rdtMonths table:first-child thead tr:before, .rdtYears table:first-child thead tr:before  { content: ""; display: block; min-width: 16px !important; width: 16px !important; height: 10px; }
.rdtDays tfoot tr:after, .rdtDays thead tr:after, .rdtDays tbody tr:after, .rdtMonths table:first-child thead tr:after, .rdtYears table:first-child thead tr:after  { content: ""; display: block; min-width: 16px !important; width: 16px !important; height: 10px; } 
.rdtDays thead tr:last-child th { font-weight: 400; }

.rdtTime tbody td { width: 100%; }
.rdtTime tbody tr:before, .rdtTime tbody tr:after { content: ""; display: block; min-width: 16px !important; width: 16px !important; height: 10px;}

.rdtPicker th { border: none }
.rdtPicker th.rdtSwitch, .rdtPicker th.rdtPrev, .rdtPicker th.rdtNext { border-radius: 5px; height: 30px; vertical-align: middle; }
.rdtPrev, .rdtNext { width: 40px !important; padding:0 }
.rdtPrev:hover, .rdtNext:hover { background: none; }
/*.rdtSwitch:hover { background: rgba(41,41,41,0.09);}*/
.rdtPrev span { background:url(../Assets/icon-chevron-left.svg) center center no-repeat !important; background-size: 24px 24px; text-indent: -9999px; height: 100%; border-radius: 5px; }
.rdtPrev span:hover { background: rgba(41,41,41,0.09) url(../Assets/icon-chevron-left.svg) center center no-repeat !important; background-size: 24px 24px; text-indent: -9999px;} 
.rdtNext span { background:url(../Assets/icon-chevron-right.svg) center center no-repeat !important; background-size: 24px 24px; text-indent: -9999px; height: 100%; border-radius: 5px; }
.rdtNext span:hover { background: rgba(41,41,41,0.09) url(../Assets/icon-chevron-right.svg) center center no-repeat !important; background-size: 24px 24px; text-indent: -9999px;}

.rdtMonths table:first-child, .rdtYears table:first-child { margin-bottom: 10px; }
.rdtMonths table:first-child thead:after, .rdtYears table:first-child thead:after { content: ""; display: block; height:1px; width: 100%; background: #8C8C8C; position: absolute; margin-top: 10px;}
.rdtMonths table:first-child tbody:before, .rdtYears table:first-child tbody:before { content: ""; display: block; height:16px; width: 100%; }
.rdtMonths table:last-child, .rdtYears table:last-child { margin-top: 18px; border-spacing: 22px; border-collapse: separate; }

.rdtMonths .rdtPrev, .rdtMonths .rdtPrev:hover, .rdtYears .rdtPrev, .rdtYears .rdtPrev:hover { background-position-x: left !important; }
.rdtMonths .rdtNext, .rdtMonths .rdtNext:hover, .rdtYears .rdtNext, .rdtYears .rdtNext:hover { background-position-x: right !important; }
.rdtMonths .rdtSwitch, .rdtYears .rdtSwitch { width: 64.5% !important; }
